export function sendToHatch(formData) {
  // Construct the data payload for Hatch
  const hatchData = {};
  for (const [key, value] of formData.entries()) {
    hatchData[key] = value;
  }

  // Map specific fields to Hatch API requirements
  hatchData.firstName = formData.get('name') || '';
  hatchData.phoneNumber = formData.get('phone') || '';
  hatchData.source = 'custom:website';

  // Remove the original name and phone fields to avoid duplication
  delete hatchData.name;
  delete hatchData.phone;
  delete hatchData.referrer;

  return fetch('/api/hatch-proxy', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.HATCH_API_TOKEN}`,
    },
    body: JSON.stringify(hatchData),
  })
  .then(response => {
    if (!response.ok) {
      console.error(`Proxy API error: ${response.statusText}`);
    }
    return response.json();
  })
  .catch(error => {
    console.error('Error sending data to proxy:', error);
  });
}
